<div class="d-flex flex-column justify-content-center align-items-center">
  <p class="text-center">
    The application has been updated, or you may be offline.
    Please check your connection and click the refresh button to continue.
  </p>
  <app-button
    class="--primary"
    icon="rmv-icon-ic-download"
    (clickEvent)="onRefresh()"
  >Refresh</app-button>
</div>
