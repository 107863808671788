import { Component } from '@angular/core';
import { UiModule } from '../ui/ui.module';

@Component({
  selector: 'app-reload-app-modal',
  templateUrl: './reload-app-modal.component.html',
  styleUrls: [ './reload-app-modal.component.scss' ],
  standalone: true,
  imports: [
    UiModule,
  ]
})
export class ReloadAppModalComponent {
  constructor() {
  }

  onRefresh(): void {
    window.location.reload();
  }
}
