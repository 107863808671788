import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../../layout/breadcrumb/breadcrumb.service';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { inputIsNotNull, inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { selectGetAccount, selectGetAccountLoading } from '../get-account/store/get-account.selectors';
import { updateAccountVisitsAction } from '../../user-history/update-account-visits/store/update-account-visits.actions';
import { DailyRouteUserIdService } from '../../core/services/daily-route-user-id.service';
import { getAccountAction } from '../get-account/store/get-account.actions';
import { Account } from '../get-accounts/get-accounts.service';
import { selectCurrentUserId, selectCurrentUserType } from "../../current-user/store/current-user.selectors";
import { UserTypes } from "../../users/user.types";
import { selectGetRelatedUsers } from "../../users/get-related-users/store/get-related-users.selectors";
import { AccountScope } from '../../core/account.types';
import { selectGetAccounts } from '../get-accounts/store/get-accounts.selectors';
import { AccountVisibility, AccountVisibilityService, AccountVisibilityValue } from '../account-visibility.service';
import { getBaseCompanyAccountsByIdsAction } from '../get-base-company-accounts-by-ids/store/get-base-company-accounts-by-ids.actions';
import { PageMetaService } from '../../core/page-meta.service';
import { getChildAccountsAction } from '../get-child-accounts/store/get-child-accounts.actions';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerConfigService, getDatepickerConfig } from '../../core/services/datepicker-config.service';
import { selectGetCompanyIndustry } from '../../company/get-company-users/store/get-company-users.selectors';

@Component({
  selector: 'app-user-account-details',
  templateUrl: './account-details-layout.component.html',
  styleUrls: [ './account-details-layout.component.scss' ],
  providers: [
    {
      provide: NgbDatepickerConfig,
      useFactory: getDatepickerConfig,
      deps: [ DatepickerConfigService ],
    },
  ],
})
export class AccountDetailsLayoutComponent implements OnInit, OnDestroy {
  currentUserType$ = this.store$.pipe(
    select(selectCurrentUserType),
    filter(inputIsNotNullOrUndefined),
  );
  isLoading$ = this.store$.pipe(select(selectGetAccountLoading));
  accounts$ = this.store$.pipe(select(selectGetAccounts));
  account$ = <Observable<Account | null>>this.store$.pipe(select(selectGetAccount));
  allowToViewAccount$: Observable<AccountVisibility | undefined> = combineLatest([
    this.store$.pipe(select(selectCurrentUserId), filter(inputIsNotNullOrUndefined)),
    this.store$.pipe(select(selectCurrentUserType), filter(inputIsNotNullOrUndefined)),
    this.store$.pipe(select(selectGetRelatedUsers), filter(inputIsNotNullOrUndefined)),
    this.account$,
    this.accounts$,
  ]).pipe(
    switchMap(([ currentUserId, currentUserType, relatedUsers, account, accounts ]) => {
      if ((account && currentUserType === UserTypes.OWNER)
        || [ UserTypes.LEAD, UserTypes.INSIDE_SALES ].includes(currentUserType) && relatedUsers.some(u =>
          !!account?.users?.ids.includes(u.id)
        )
        || !!account?.users?.ids.includes(currentUserId)) {
        return of<AccountVisibility>({ visibility: AccountVisibilityValue.edit });
      } else if (account && (accounts || []).some(a => a.parentIds?.includes(account.id) || account.parentIds?.includes(a.id))) {
        return of({ visibility: AccountVisibilityValue.view });
      } else {
        return of(undefined);
      }
    }),
  );
  industry$ = this.store$.pipe(select(selectGetCompanyIndustry));
  private readonly unsubscribe$ = new Subject<void>();

  accountCaptions = AccountScope;

  constructor(
    public route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private store$: Store,
    private dailyRouteUserIdService: DailyRouteUserIdService,
    private accountVisibilityService: AccountVisibilityService,
    private pageMetaService: PageMetaService,
  ) {
  }

  ngOnInit(): void {
    const params$ = this.route.params;
    if (!params$) throw new Error('Unexpected: Missed params');

    params$.pipe(
      filter(({ accountId }) => accountId),
      takeUntil(this.unsubscribe$),
    ).subscribe(({ accountId }) =>
      this.store$.dispatch(getAccountAction({ id: accountId }))
    );

    this.account$.pipe(
      filter(inputIsNotNullOrUndefined),
      takeUntil(this.unsubscribe$),
    ).subscribe(account => {
      this.pageMetaService.patchMeta({ accountName: account.name });
      this.breadcrumbService.set({
        name: 'accounts',
        list: [
          {
            caption: 'Accounts',
            link: [ '/prospects' ],
            active: false,
          },
          {
            caption: account.name,
            link: [],
            active: true,
          }
        ]
      });

      const accountId = this.route.snapshot.paramMap.get('accountId');

      if (!accountId) {
        throw new Error('Missed account ID');
      }

      this.store$.dispatch(updateAccountVisitsAction({ accountId }));
    });

    combineLatest([
      this.account$.pipe(filter(inputIsNotNull)),
      this.currentUserType$.pipe(filter(currentUserType => currentUserType !== UserTypes.OWNER)),
    ]).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(([ account ]) => {
      if (account.scope === AccountScope.child && account.parentIds) {
        this.store$.dispatch(getBaseCompanyAccountsByIdsAction({
          payload: {
            ids: account.parentIds,
          },
        }));
      } else {
        this.store$.dispatch(getChildAccountsAction({
          payload: {
            id: account.id,
          }
        }));
      }
    });

    this.allowToViewAccount$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(visibility =>
        this.accountVisibilityService.set(visibility)
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.breadcrumbService.clear();
    this.dailyRouteUserIdService.clear();
    this.pageMetaService.clearMeta();
  }
}
