import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChunkErrorService {
  errorEvent = new EventEmitter<void>();

  constructor() {
  }

  emit(): void {
    this.errorEvent.emit();
  }
}
