import { ErrorHandler, Injectable } from '@angular/core';
import { ChunkErrorService } from './services/chunk-error.service';

const chunkErrorMessages = [
  'Loading chunk [a-z_\\d]+ failed',
  'Failed to fetch dynamically imported module',
  'error loading dynamically imported module',
  'Importing a module script failed',
];

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private chunkErrorService: ChunkErrorService) {
  }

  handleError(error: unknown): void {
    if (error instanceof Error) {
      if (chunkErrorMessages.some(m => new RegExp(m).test(error.message))) {
        this.chunkErrorService.emit();
      }
    }
  }
}
